import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/index'},
  {
    path: "/index",
    name: "Home",
    meta:{
      title:"首页"
    }, 
    component: () =>
    import(/* webpackChunkName: "index" */ "../views/index.vue")

  },
  {
    path: "/product",
    name: "Product",
    meta:{
      title:"产品中心"
    },
    component: () =>
    import(/* webpackChunkName: "index" */ "../views/product.vue")

  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/number",
    name: "Number",
    meta:{
      title:"数字航运"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/number.vue")
  },
  {
    path: "/news",
    name: "News",
    meta:{
      title:"新闻资讯"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/news.vue")
  },
  {
    path: "/newsDetail/:id",
    name: "NewsDetail",
    meta:{
      title:"新闻资讯"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "newsDetail" */ "../views/newsDetail.vue")
  },
  {
    path: "/recruit",
    name: "Recruit",
    meta:{
      title:"招贤纳士"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/recruit.vue")
  },
  {
    path: "/aboutus",
    name: "Aboutus",
    meta:{
      title:"关于我们"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/aboutus.vue")
  },
  {
    path: "/goods",
    name: "Goods",
    meta:{
      title:"动力中心"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/goods.vue")
  },
  {
    path: "/animation",
    name: "Animation",
    meta:{
      title:"css3动画"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/other/animation.vue")
  },
  {
    path: "/banner/:bannerId",
    name: "banner",
    meta:{
      title:"广告",
      banner:"this.is banner"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/banner.vue")
  },
  {
    path: "/aboutforapp",
    name: "aboutforapp",
    meta:{
      title:"关于我们",
      banner:"this.is aboutforapp"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/aboutforapp.vue")
  },
  {
    path: "/banners",
    name: "banners",
    meta:{
      title:"广告",
      banner:"this.is banner"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/banners.vue")
  },
  {
    path: "/addoil",
    name: "AddOil",
    meta:{
      title:"加油",
      banner:"this.is addoil"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/addoil.vue")
  },
  {
    path: "/weather",
    name: "Weather",
    meta:{
      title:"天气",
      banner:"this.is weather"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/weather.vue")
  },
  {
    path: "/newyear",
    name: "newYear",
    meta:{
      title:"新年",
      banner:"新年"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/newYear.vue")
  },
  {
    path: "/report",
    name: "report",
    meta:{
      title:"新年",
      banner:"新年"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/report.vue")
  },
  {
    path: "/happy51",
    name: "happy51",
    meta:{
      title:"happy51",
      banner:"happy51"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/happy51.vue")
  },
  {
    path: "/test",
    name: "test",
    meta:{
      title:"test",
      banner:"test"
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "aboutus" */ "../views/test.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;

