import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./css/reset.css"
import { message} from 'ant-design-vue'




import '../node_modules/amfe-flexible/index.js'


import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);


import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import { Swipe, SwipeItem } from 'vant';
Vue.use(Swipe);
Vue.use(SwipeItem);

import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
router.beforeEach((to, from, next) => {
  console.log("to::",to)
  let banner;
  if(to.meta.banner){
    banner = 1
  }else{
    banner =0
  }
  Vue.prototype.banner = banner
  document.documentElement.scrollTop = 0
  if (to.meta &&to.meta.title) {
   // document.title = to.meta.title
  }
 next()
})
router.afterEach((to, from, next)=>{
  document.getElementById( 'newBridge' ) && document.getElementById( 'newBridge' ).remove();
  if(to.path=='/index'||to.path=='/number'||to.path=='/aboutus'||to.path=='/product'){
    var _hmt = _hmt || [];
    (function() {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?f7177983236c3f19459772744dce0163";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
    })();
  }
})


Vue.config.productionTip = false;


message.config({
  top: `350px`,
  duration: 2,
  maxCount: 3,
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
