<template>
    <div id="app">
        <template v-if="!banner">
            <div id="nav" class="nav">
                <img src="./assets/images/logo.png" class="logo" />
                <div style="width:100px;height:50px;border:0px solid red;top:0;right:0;position:fixed" @click="handleMenuShow" v-if="menuShow">
                    <img src="./assets/images/icon-topright-closed.png" class="top_right" style="width:22px;right:12px;top:15px" />
                </div>
                <div v-else style="width:100px;height:50px;border:0px solid red;top:0;right:0;position:fixed" @click="handleMenuShow">
                    <img src="./assets/images/icon-topright.png" class="top_right" />
                </div>

            </div>
            <div class="menu" style="position:fixed;right:5px;top:50px;z-index:1000;border:0px solid yellow">
                <!--
       <a-dropdown  class="navBtn" style="border:1px solid green">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()" style="border:0px solid red">
            
          </a>
          <a-menu slot="overlay" @click="handleMenu" theme="dark" :selected-keys="selectedKeys"  style="position:fixed;right:0;top:50px">
             <a-menu-item  v-for ="(item) in menuList" :key="item.key" :data-to="item.to" :keypath="item.to">
             {{item.title}}
            </a-menu-item>
          </a-menu>
     </a-dropdown>
     -->

                <a-menu slot="overlay" @click="handleMenu" theme="dark" :selected-keys="selectedKeys" style="position:fixed;right:10px;top:50px;text-align:center" v-if="menuShow">
                    <a-menu-item v-for="(item) in menuList" :key="item.key" :data-to="item.to" :keypath="item.to">
                        {{item.title}}
                    </a-menu-item>
                </a-menu>

            </div>

            <router-view />

            <!---footer-->
            <div class="footer">
                <div class="copyRight">版权所有：江苏好三由信息科技有限公司</div>
                <div class="code_item">
                    <p>招商微信</p>
                    <img src="./assets/images/index_code.jpg" />
                </div>
                <div class="code_item">
                    <p>微信公众号</p>
                    <img src="./assets/images/wechat_code.jpg" />
                </div>
                <div class="companyInfoFooter">
                    <p class="telText">客服电话</p>
                    <div class="tel">
                        <a href="tel:025-83766081"><img src="./assets/images/index_tel.png" alt=""></a>
                        <a href="tel:025-83766081"> <span style="color:#fff;">025-83766081</span></a>
                    </div>

                    <p style="color:#fff" class="tel bei">
                        <a style="color:#fff;font-size:14px" href="tel:15312027044">15312027044</a>
                    </p>
                    <p class="time">
                        <span style="margin-right:4px">周一至周六</span>
                        <span style="color:#fff">9:00 ~ 18:00</span>
                    </p>
                    <p style="color:#fff" class="bei">苏ICP备18068996</p>

                </div>
            </div>
            <!---END footer-->
        </template>
        <router-view v-else />
    </div>
</template>
<script>
export default {
    data() {
        return {
            menuList: [
                { key: "1", title: "首    页", to: "/index" },
                { key: "2", title: "产品中心", to: "/product" },
                { key: "3", title: "数字航运", to: "/number" },
                { key: "4", title: "新闻资讯", to: "/news" },
                { key: "5", title: "招贤纳士", to: "/recruit" },
                { key: "6", title: "关于我们", to: "/aboutus" },
            ],
            selectedKeys: ["1"],
            menuShow: false,
        };
    },
    created() {
        console.log("==", this.$route);
    },
    mounted() {
        console.log("==", this.$route);
    },
    watch: {
        "$route.path": {
            immediate: true,
            handler() {
                let path = this.$route.path;
                console.log("pathss::", path);
                let selectedKeys = [];
                this.menuList.forEach((item) => {
                    if (item.to == path) {
                        selectedKeys = [item.key];
                    }
                });
                this.selectedKeys = selectedKeys;
                this.menuShow = false;
            },
        },
    },

    methods: {
        handleMenu(obj) {
            let keypath = obj.keyPath;
            let to;
            this.menuList.forEach((item) => {
                if (item.key == keypath) {
                    to = item;
                }
            });
            //  this.selectedKeys = [to.key]
            this.$router.push(to.to);
        },
        handleMenuShow() {
            console.log("menu");
            this.menuShow = !this.menuShow;
        },
    },
};
</script>
<style lang="scss">
#appbak {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.nav {
    width: 100%;
    height: 50px;
    background: rgba(0, 0, 0, 0.65);
    position: fixed;
    z-index: 10;
}
.nav .logo {
    width: 130px;
    position: absolute;
    left: 10px;
    top: 13px;
}
.nav .top_right {
    width: 25px;
    position: absolute;
    right: 10px;
    top: 13px;
    display: block;
}
.ant-dropdown-menu.ant-dropdown-menu.ant-dropdown-content {
    background: #070c37 !important;
    color: #fff !important;
    width: 140px;
    text-align: center;
    letter-spacing: 1px;
}
.ant-dropdown-menu-item > a {
    color: #ddd !important;
}
.ant-dropdown-menu-item > a > .router-active {
    color: #fff !important;
    background: #333;
}

.nav .headerImg {
    display: block;
    width: 100%;
}

.footer {
    background: #000;
    height: 130px;
    margin-top: 0px;
    position: relative;
    font-size: 12px;
}
.copyRight {
    text-align: center;
    color: #fff;
    font-size: 12px;
    line-height: 40px;
}
.code_item {
    width: 44px;
    height: 62px;
    float: left;
    margin-left: 12px;
    margin-top: 4px;
}
.code_item p {
    width: 44px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    border: 1px solid #555;
    margin: 0 auto;
    font-size: 8px;
    color: #fff;
}
.code_item img {
    display: block;
    width: 42px;
    margin: 0 auto;
    margin-top: 7px;
}
.companyInfoFooter {
    width: 150px;
    height: 75px;
    position: absolute;
    right: 10px;
    top: 42px;
    color: #888;
    text-align: right;
    font-size: 11px;
    line-height: 150%;
}
.companyInfoFooter .telText {
    border: 0px solid red;
    margin: 0;
}
.companyInfoFooter .tel {
    height: 18px;
    margin-top: 3px;
    font-size: 14px;
    border: 0px solid red;
}
.companyInfoFooter .tel img {
    display: inline-block;
    width: 13px;
    margin-right: 10px;
}
.companyInfoFooter .bei {
    border: 0px solid red;
    margin: 0;
}
.companyInfoFooter .time {
    border: 0px solid red;
    margin: 0;
}
.navBtn {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 50px;
    z-index: 1000000;
    border: 1px solid transparent;
    color: #fff;
}
.router-active {
    background: #666;
    color: #fff;
}

.menuList {
    position: absolute;
    right: 0;
    top: 50px;
    text-align: center;
    color: #fff;
    box-shadow: 0 0 5px #ddd;
    font-size: 14px;
}
.menuList > li > div {
    width: 100px;
    height: 25px;
    line-height: 25px;
    background: #2b334e;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
#newBridge .nb-icon-right-center {
    top: 80% !important
}
</style>
